import {Directive, ElementRef, inject, Input} from '@angular/core';

@Directive({
  selector: '[apsBtnPending]',
  standalone: true,
})
export class BtnPendingDirective {
  private readonly elem = inject(ElementRef);
  private _btnPending!: boolean;
  @Input('apsBtnPending') get btnPending(): boolean {
    return this._btnPending;
  }

  set btnPending(pending: boolean) {
    this._btnPending = pending;
    if (pending) {
      this.elem.nativeElement.classList.add('loading');
    } else {
      this.elem.nativeElement.classList.remove('loading');
    }
  }
}
