import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {ValueOrDefaultPipe} from '@shared/pipes';

@Component({
  selector: 'aps-ag-cell-oc-number',
  template: `
    <div class="flex items-center">
      <mat-icon class="color-medium size-sm mr-2" svgIcon="oc-number" />
      <div> {{ value | apsValueOrDefault }}</div>
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ValueOrDefaultPipe,
    MatIcon
  ]
})
export class AgCellOcNumberComponent implements ICellRendererAngularComp {
  value!: string;

  agInit(params: ICellRendererParams): void {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    return false;
  }

}
