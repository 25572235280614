import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {RouterOutlet} from '@angular/router';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {KeycloakEvent} from 'keycloak-angular/lib/core/interfaces/keycloak-event';
import {filter, tap} from 'rxjs';

@Component({
  selector: 'aps-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  private readonly matIconRegistry = inject(MatIconRegistry);
  private readonly domSanitizer = inject(DomSanitizer);
  private readonly keycloak = inject(KeycloakService);

  ngOnInit(): void {
    this.initCustomMaterialIcons();
    this.subscribeToExpiredTokenEvents();
  }

  private initCustomMaterialIcons(): void {
    this.matIconRegistry
      .addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/symbol-defs.svg'));
  }

  private subscribeToExpiredTokenEvents(): void {
    this.keycloak.keycloakEvents$.pipe(
      filter((e: KeycloakEvent) => e.type === KeycloakEventType.OnTokenExpired),
      tap(() => {
        void this.keycloak.logout();
      })
    ).subscribe();
  }
}
