import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {catchError, filter, of} from 'rxjs';
import {switchMap, take, tap} from 'rxjs/operators';

import {OrganizationResponse, OrganizationStatus} from '@features/organizations/models';
import {OrganizationService} from '@features/organizations/services';
import {ConfirmActionDialogComponent} from '@shared/components';
import {BtnPendingDirective, LetDirective} from '@shared/directives';
import {ConfirmActionDialogData} from '@shared/models';
import {NotificationService} from '@shared/services';

@Component({
  selector: 'aps-organization-deactivate',
  templateUrl: './organization-deactivate.component.html',
  standalone: true,
  imports: [CommonModule, BtnPendingDirective, MatButton, LetDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationDeactivateComponent {
  private readonly organizationService = inject(OrganizationService);
  private readonly notificationService = inject(NotificationService);
  private readonly dialog = inject(MatDialog);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  @Input() organization!: OrganizationResponse;
  @Output() changed = new EventEmitter<OrganizationResponse>();
  pending$ = this.organizationService.deletePending$;
  organizationStatus = OrganizationStatus;

  deactivate(): void {
    const confirmActionDialogData: ConfirmActionDialogData = {
      title: 'Organization will be deactivated',
      description: 'Do you want to deactivate this organization?'
    };

    this.dialog.open<ConfirmActionDialogComponent, ConfirmActionDialogData>(ConfirmActionDialogComponent, {
      width: '504px',
      data: confirmActionDialogData
    }).afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.organizationService.changeStatus(this.organization?.id, OrganizationStatus.inactive)),
        tap(() => {
          this.notificationService.success('Organization successfully deactivated');
          this.changed.emit({
            ...this.organization,
            status: OrganizationStatus.inactive
          });
          this.changeDetectorRef.markForCheck();
        }),
        catchError(() => {
          this.notificationService.error('Something went wrong');
          return of(null);
        })
      )
      .subscribe();
  }

  activate(): void {
    const confirmActionDialogData: ConfirmActionDialogData = {
      title: 'Organization will be activated',
      description: 'Do you want to activate organization?'
    };

    this.dialog.open<ConfirmActionDialogComponent, ConfirmActionDialogData>(ConfirmActionDialogComponent, {
      width: '504px',
      data: confirmActionDialogData
    }).afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.organizationService.changeStatus(this.organization?.id, OrganizationStatus.active)),
        tap(() => {
          this.notificationService.success('Organization successfully activated');
          this.changed.emit({
            ...this.organization,
            status: OrganizationStatus.active
          });
          this.changeDetectorRef.markForCheck();
        }),
        catchError(() => {
          this.notificationService.error('Something went wrong');
          return of(null);
        })
      )
      .subscribe();
  }
}
