import {DOCUMENT} from '@angular/common';
import {inject, Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CopyToClipboardService {
  private readonly document = inject(DOCUMENT);

  async copyText(copyValue: string): Promise<boolean> {
    // create temporary element
    const tempElement = this.document.createElement('textarea');
    const styles = tempElement.style;
    const body = this.document.body;

    // Hide the element for display and accessibility
    styles.opacity = '0';
    styles.position = 'absolute';
    styles.left = styles.top = '-999em';
    tempElement.setAttribute('aria-hidden', 'true');
    tempElement.value = copyValue;
    body.appendChild(tempElement);

    // select created element and copy it
    tempElement.select();
    tempElement.setSelectionRange(0, tempElement.value.length);
    let copied = false;
    try {
      await navigator.clipboard.writeText(tempElement.value);
      copied = true;
    } catch (err) {
      copied = false;
    }
    // cleanup
    body.removeChild(tempElement);
    return copied;
  }
}
