import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom, Provider} from '@angular/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter} from '@angular/router';
import {environment} from '@environments/environment';
import {KeycloakBearerInterceptor, KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {KeycloakEvent} from 'keycloak-angular/lib/core/interfaces/keycloak-event';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {NgxPermissionsModule} from 'ngx-permissions';
import {filter, switchMap, take, tap} from 'rxjs';

import {AppInitializerService} from '@core/app-initializer/app-initializer.service';
import {UserService} from '@features/users/services';

import {appRoutes} from './app.routes';

const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  deps: [KeycloakService],
  multi: true
};

const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true
};

const InitializePermissionsProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializePermissions,
  deps: [KeycloakService, AppInitializerService, UserService],
  multi: true
};

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: environment.keycloakRealm,
        clientId: environment.keycloakClientId
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      }
    }).catch((e: unknown) => {
      if ((e as { error: string })?.error === 'access_denied') {
        void keycloak.logout();
      }
    });
}

function initializePermissions(
  keycloakService: KeycloakService,
  appInitializerService: AppInitializerService,
  userService: UserService
) {
  return () =>
    keycloakService.keycloakEvents$.pipe(
      filter((e: KeycloakEvent) => e.type === KeycloakEventType.OnReady),
      take(1),
      switchMap(() => userService.loadMyProfileAndSetPermissions(keycloakService.getUserRoles())),
      tap(() => appInitializerService.init())
    );
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    KeycloakInitializerProvider,
    KeycloakBearerInterceptorProvider,
    KeycloakService,
    importProvidersFrom(
      NgxPermissionsModule.forRoot()
    ),
    provideEnvironmentNgxMask(),
    InitializePermissionsProvider,
    provideRouter(appRoutes)
  ]
};
