import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';

import {Application} from '@features/organizations/models';

@Injectable({ providedIn: 'root' })
export class ApplicationApiService {
  private readonly httpClient = inject(HttpClient);

  getList(): Observable<Application[]> {
    return this.httpClient.get<Application[]>(`${environment.baseURL}/applications`);
  }
}
