import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatIcon} from '@angular/material/icon';

import {UserBase} from '@features/users/models';
import {FullNamePipe, ValueOrDefaultPipe} from '@shared/pipes';

@Component({
  selector: 'aps-project-history-card',
  templateUrl: './project-history-card.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FullNamePipe,
    ValueOrDefaultPipe,
    MatIcon
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectHistoryCardComponent {
  @Input() modifiedBy!: UserBase;
  @Input() createdBy!: UserBase;
  @Input() lastUpdatedDate!: string;
  @Input() createdDate!: string;
  dateFormat = 'MMM d y, hh:mm a';
}
