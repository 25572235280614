import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {ILoadingOverlayAngularComp} from 'ag-grid-angular';
import {ILoadingOverlayParams} from 'ag-grid-community';


@Component({
  selector: 'aps-ag-grid-loader',
  standalone: true,
  imports: [CommonModule, MatProgressSpinner],
  templateUrl: './custom-ag-grid-loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomAgGridLoaderComponent implements ILoadingOverlayAngularComp {
  params!: ILoadingOverlayParams;

  agInit(params: ILoadingOverlayParams ): void {
    this.refresh(params);
  }

  refresh(params: ILoadingOverlayParams ): void {
    this.params = params;
  }
}
