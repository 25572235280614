import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {ValueOrDefaultPipe} from '@shared/pipes';

const toolsMap: Record<string, string> = {
  'DE4': 'DTS',
  'DE5': 'DAS'
};

@Component({
  selector: 'aps-ag-cell-tools',
  template: `
    <div class="flex items-center">
      <mat-icon class="color-medium size-sm mr-2" svgIcon="tools" />
      <div> {{ tools | apsValueOrDefault }}</div>
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ValueOrDefaultPipe, MatIcon]
})
export class AgCellToolsComponent implements ICellRendererAngularComp {
  value!: string[];
  tools!: string;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    this.tools = this.getTools(this.value);
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.value;
    this.tools = this.getTools(this.value);
    return false;
  }

  getTools(serialNumbers: string[]): string {
    const uniqueElements = new Set<string>();

    serialNumbers?.forEach((sn) => {
      const key = sn.substring(0, 3);
      const val = toolsMap[key.toUpperCase()];
      if (val) {
        uniqueElements.add(val);
      }
    });

    return Array.from(uniqueElements.values()).join(', ');
  }

}
