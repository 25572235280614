import {Observable} from 'rxjs';

import {ListResponse} from '@core/common';


export interface AgGridState<T = unknown> {
  pending$: Observable<boolean>;
  data$: Observable<ListResponse<T>>;
}

export enum AgGridSort {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}

export interface AgGridSortState {
  propertyName: string | null;
  order: AgGridSort;
}
