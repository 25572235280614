import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

import {NotificationService} from '@shared/services';

import {CopyToClipboardService} from './copy-to-clipboard.service';

@Component({
  selector: 'aps-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyToClipboardComponent {
  private readonly notificationService = inject(NotificationService);
  private readonly copyToClipboardService = inject(CopyToClipboardService);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly switchIconsTimeout = 2000;

  @Input() value = '';
  @Input() tooltipText = 'Copy to clipboard';
  @Input() successText = 'Copied to clipboard';
  isCopied = false;

  async copyToClipboard($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.isCopied = await this.copyToClipboardService.copyText(this.value);
    this.notificationService.success(this.successText);
    this.cd.markForCheck();

    setTimeout(() => {
      this.isCopied = false;
      this.cd.markForCheck();
    }, this.switchIconsTimeout);
  }
}
