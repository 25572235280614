import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {Router} from '@angular/router';

import {DEFAULT_PAGE_SIZE} from '@core/common';
import {LocalStorageService} from '@core/services';

@Component({
  selector: 'aps-base-list',
  standalone: true,
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseListComponent implements OnInit {
  @Input() consumer!: 'projects' | 'users' | 'organizations' | 'event-reporting-projects';
  @Input() pageSize = DEFAULT_PAGE_SIZE;
  protected readonly localStorage = inject(LocalStorageService);
  protected readonly router = inject(Router);

  ngOnInit(): void {
    this.setDefaultPageSettings();
  }

  updatePagination(event: PageEvent): void {
    this.localStorage.addItem(this.getGridSettingsStorageKey(), String(event.pageSize));
  }

  private setDefaultPageSettings(): void {
    const storageKey = this.getGridSettingsStorageKey();
    const pageSize = this.localStorage.getItem(storageKey);
    this.pageSize = pageSize ? Number(pageSize) : this.pageSize;
  }

  private getGridSettingsStorageKey(): string {
    return `${this.consumer}_${this.router.url}`;
  }
}
