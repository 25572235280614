import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, catchError, finalize, ReplaySubject, take, tap, throwError} from 'rxjs';

import {Maybe} from '@core/common';
import {Application} from '@features/organizations/models';

import {ApplicationApiService} from './application-api.service';


@Injectable({ providedIn: 'root' })
export class ApplicationService {
  private readonly applicationApiService = inject(ApplicationApiService);
  private _applications$ = new ReplaySubject<Application[]>(1);
  private _applicationsMap$ = new ReplaySubject<Map<number, Application>>(1);
  private _pending$ = new BehaviorSubject<boolean>(false);
  private _error$ = new BehaviorSubject<Maybe<HttpErrorResponse>>(null);

  applications$ = this._applications$.asObservable();
  applicationsMap$ = this._applicationsMap$.asObservable();
  pending$ = this._pending$.asObservable();
  error$ = this._error$.asObservable();

  init(): void {
    this.fetchApplications();
  }

  private fetchApplications(): void {
    this._pending$.next(true);
    this._error$.next(null);

    this.applicationApiService.getList()
      .pipe(
        take(1),
        finalize(() => {
          this._pending$.next(false);
        }),
        catchError((err: HttpErrorResponse) => {
          this._error$.next(err);
          return throwError(() => err);
        }),
        tap((res) => {
          const applicationsMap = new Map<number, Application>();
          res.forEach((c) => {
            applicationsMap.set(c.id, c);
          });

          this._applicationsMap$.next(applicationsMap);
          this._applications$.next(res);
        })
      )
      .subscribe();
  }
}
