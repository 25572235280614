import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle} from '@angular/material/card';
import {MatIcon} from '@angular/material/icon';

import {UserBase, UserDto} from '@features/users/models';
import {FullNamePipe, TruncatePipe, UserRolePipe, ValueOrDefaultPipe} from '@shared/pipes';

@Component({
  selector: 'aps-user-card',
  templateUrl: './user-card.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatCard,
    MatCardHeader,
    MatCardTitle,
    FullNamePipe,
    MatCardSubtitle,
    ValueOrDefaultPipe,
    UserRolePipe,
    TruncatePipe,
    MatIcon,
    MatCardContent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCardComponent {
  @Input() user!: UserBase;
  @Input() showOrganizations = false;

  get userOrganizations(): string | null {
    return (this.user as UserDto)?.organizations?.map(o => o.name).join(', ');
  }
}
