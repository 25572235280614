import {Pipe, PipeTransform} from '@angular/core';

import {Maybe} from '@core/common';

@Pipe({
  name: 'apsFullName',
  pure: true,
  standalone: true
})
export class FullNamePipe implements PipeTransform {
  static transform(value: { firstName?: string; lastName?: string; }): Maybe<string> {
    return [value?.firstName, value?.lastName].filter(Boolean).join(' ') || null;
  }

  transform(value: { firstName?: string; lastName?: string; }): Maybe<string> {
    return FullNamePipe.transform(value);
  }
}
