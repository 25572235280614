import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {Maybe} from '@core/common';
import {Project} from '@features/projects/models';
import {UserBase} from '@features/users/models';
import {FullNamePipe, ValueOrDefaultPipe} from '@shared/pipes';

@Component({
  selector: 'aps-ag-cell-user-list',
  template: `{{ value | apsValueOrDefault }}<span *ngIf="count" class="ml-2">{{ count }}</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ValueOrDefaultPipe, NgIf]
})
export class AgCellUserListComponent implements ICellRendererAngularComp {
  value!: Maybe<string>;
  count!: Maybe<string>;

  agInit(params: ICellRendererParams<Project, UserBase[]>): void {
    this.init(params.value! );
  }

  refresh(params: ICellRendererParams): boolean {
    this.init(params.value as UserBase[]);
    return false;
  }

  private init(users: UserBase[]) {
    this.value = this.getValue(users);
    this.count = this.getCount(users);
  }

  private getValue(users: UserBase[]): string | null {
    return users?.length ? FullNamePipe.transform(users[0]) : null;
  }

  private getCount(users: UserBase[]): string | null {
    return users?.length > 1 ? `+${users.length - 1}` : '';
  }
}
