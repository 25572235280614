import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';

import {DEFAULT_MODAL_WIDTH} from '@core/common';

import {OrganizationCreateComponent} from '../organization-create';

@Component({
  selector: 'aps-organization-create-btn',
  templateUrl: './organization-create-btn.component.html',
  standalone: true,
  imports: [
    MatIcon,
    MatButton
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationCreateBtnComponent {
  private readonly matDialog = inject(MatDialog);

  showDialog(): void {
    this.matDialog.open(OrganizationCreateComponent, {
      width: DEFAULT_MODAL_WIDTH,
      closeOnNavigation: true
    });
  }
}
