import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  finalize,
  Observable,
  ReplaySubject,
  switchMap,
  tap,
  throwError
} from 'rxjs';

import {
  BackendError,
  ErrorCode,
  GpsCoordinates,
  ListResponse,
  Maybe,
  Pagination
} from '@core/common';
import {
  EventReportProject,
  EventReportProjectCreateReq,
  EventReportProjectListEntry,
  EventReportProjectUpdateReq,
} from '@features/projects/models';
import {AgGridSortState, ProjectFilter} from '@shared/models';

import {EventReportingApiService} from './event-reporting-api.service';

@Injectable({ providedIn: 'root' })
export class EventReportingService {
  private readonly eventReportingApi = inject(EventReportingApiService);

  private _refreshList$ = new BehaviorSubject<boolean>(false);
  private _pendingList$ = new BehaviorSubject<boolean>(false);
  private _errorList$ = new BehaviorSubject<Maybe<HttpErrorResponse>>(null);
  private _createPending$ = new BehaviorSubject<boolean>(false);
  private _updatePending$ = new BehaviorSubject<boolean>(false);
  private _getByIdPending$ = new BehaviorSubject<boolean>(false);
  private pagination$ = new ReplaySubject<Pagination>(1);
  private sortingState$ = new BehaviorSubject<Maybe<AgGridSortState[]>>(null);
  private filterState$ = new BehaviorSubject<Maybe<ProjectFilter>>(null);

  pendingList$ = this._pendingList$.asObservable();
  updatePending$ = this._updatePending$.asObservable();
  getByIdPending$ = this._getByIdPending$.asObservable();

  getProjects(): Observable<ListResponse<EventReportProjectListEntry>> {
    return combineLatest([
      this.pagination$.asObservable(),
      this.sortingState$.asObservable(),
      this.filterState$.asObservable(),
      this._refreshList$
    ])
      .pipe(
        tap(() => {
          this._pendingList$.next(true);
          this._errorList$.next(null);
        }),
        switchMap(([pagination, sorting, filter]) => this.eventReportingApi.getProjects(pagination, sorting, filter)
          .pipe(
            finalize(() => {
              this._pendingList$.next(false);
            }),
            catchError((err: HttpErrorResponse) => {
              this._errorList$.next(err);
              return throwError(() => err);
            })
          ))
      );
  }

  getById(id: number): Observable<EventReportProject> {
    this._getByIdPending$.next(true);
    return this.eventReportingApi.getById(id).pipe(
      finalize(() => this._getByIdPending$.next(false)),
    );
  }

  create(projectCreateReq: EventReportProjectCreateReq): Observable<number> {
    this._createPending$.next(true);
    return this.eventReportingApi.create(projectCreateReq)
      .pipe(
        finalize(() => {
          this._createPending$.next(false);
        }),
        tap(() => this.refreshList()),
      );
  }

  update(project: EventReportProjectUpdateReq): Observable<EventReportProject> {
    this._updatePending$.next(true);
    return this.eventReportingApi.update(project)
      .pipe(
        tap(() => this.refreshList()),
        finalize(() => this._updatePending$.next(false)),
      );
  }

  updateStatus(projectId: number, isActive: boolean): Observable<void> {
    this._updatePending$.next(true);
    return this.eventReportingApi.updateStatus(projectId, isActive)
      .pipe(
        tap(() => this.refreshList()),
        finalize(() => this._updatePending$.next(false)),
      );
  }

  searchByName(projectName: string): Observable<boolean> {
    return this.eventReportingApi.searchByName(projectName);
  }

  getProjectFileByName(projectId: number, fileName: string): Observable<Blob> {
    return this.eventReportingApi.getProjectFileByName(projectId, fileName);
  }

  uploadBlueprintFile(projectId: number, file: File): Observable<string> {
    return this.eventReportingApi.uploadBlueprintFile(projectId, file);
  }

  addProjectGpsLocation(projectId: number, gpsLocation: GpsCoordinates): Observable<number> {
    return this.eventReportingApi.addProjectGpsLocation(projectId, gpsLocation);
  }

  uploadFiberRouteFile(projectId: number, file: File): Observable<string> {
    return this.eventReportingApi.uploadFiberRouteFile(projectId, file).pipe(
      catchError((err: unknown) => {
        // Temp solution. Replace when error codes support will be added to backend
        if (err instanceof HttpErrorResponse && (err.status as HttpStatusCode) === HttpStatusCode.BadRequest) {
          if (err?.error?.isValidFile === false) {
            return throwError(() => new BackendError(
              'The file contains more than one reference route. Please select a file with only one reference route.',
              ErrorCode.INVALID_KML_KMZ
            ));
          }
        }
        return throwError(() => err);
      })
    );
  }

  refreshList(): void {
    this._refreshList$.next(!this._refreshList$.value);
  }

  setPagination(pagination: Pagination): void {
    this.pagination$.next(pagination);
  }

  setFilter(filter: ProjectFilter): void {
    this.filterState$.next(filter);
  }

  setSorting(event: AgGridSortState[] | null): void {
    this.sortingState$.next(event);
  }
}
