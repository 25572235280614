import {AbstractControl} from '@angular/forms';

export const ValidateSerialNumber = (control: AbstractControl<string>) => {
  const re = new RegExp('^DE(4|5|6)[0-9]{7}$');

  if (!control.value) {
    return null;
  }

  if (re.test(control.value)) {
    return null;
  }
  return { invalidSn: true };
};
