import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  finalize,
  map,
  Observable,
  ReplaySubject,
  switchMap,
  tap,
  throwError
} from 'rxjs';

import {ListResponse, Maybe, Pagination} from '@core/common';
import {ApplicationService} from '@core/services';
import {
  Application,
  OrganizationCreateUpdateReq,
  OrganizationListItem,
  OrganizationListItemResponse,
  OrganizationResponse,
  OrganizationStatus
} from '@features/organizations/models';
import {AgGridSortState} from '@shared/models';

import {OrganizationApiService} from './organization-api.service';


@Injectable({ providedIn: 'root' })
export class OrganizationService {
  private readonly organizationApiService = inject(OrganizationApiService);
  private readonly applicationService = inject(ApplicationService);
  private _refreshList$ = new BehaviorSubject<boolean>(false);
  private _pendingList$ = new BehaviorSubject<boolean>(false);
  private _getByIdPending$ = new BehaviorSubject<boolean>(false);
  private _createPending$ = new BehaviorSubject<boolean>(false);
  private _updatePending$ = new BehaviorSubject<boolean>(false);
  private _deletePending$ = new BehaviorSubject<boolean>(false);
  private pagination$ = new ReplaySubject<Pagination>(1);
  private sortingState$ = new BehaviorSubject<Maybe<AgGridSortState[]>>(null);

  pendingList$ = this._pendingList$.asObservable();
  getByIdPending$ = this._getByIdPending$.asObservable();
  createPending$ = this._createPending$.asObservable();
  updatePending$ = this._updatePending$.asObservable();
  deletePending$ = this._deletePending$.asObservable();

  getOrganizations(status: OrganizationStatus): Observable<ListResponse<OrganizationListItem>> {
    return combineLatest([
      this.pagination$.asObservable(),
      this.sortingState$.asObservable(),
      this.applicationService.applicationsMap$,
      this._refreshList$
    ])
      .pipe(
        tap(() => {
          this._pendingList$.next(true);
        }),
        switchMap(([
                     pagination,
                     sorting,
                     applications
                   ]) => this.organizationApiService.getOrganizations(pagination, status, sorting)
          .pipe(
            map((organizations) => this.mapList(organizations, applications)),
            finalize(() => {
              this._pendingList$.next(false);
            }),
            catchError((err: HttpErrorResponse) => {
              return throwError(() => err);
            })
          )
        )
      );
  }

  getById(id: number): Observable<OrganizationResponse> {
    this._getByIdPending$.next(true);
    return this.organizationApiService.getById(id)
      .pipe(finalize(() => {
        this._getByIdPending$.next(false);
      }));
  }

  create(createReq: OrganizationCreateUpdateReq): Observable<number> {
    this._createPending$.next(true);
    return this.organizationApiService.create(createReq)
      .pipe(
        finalize(() => {
          this._createPending$.next(false);
        }),
        tap(() => {
          this.refreshList();
        })
      );
  }

  update(id: number, updateReq: OrganizationCreateUpdateReq): Observable<number> {
    this._updatePending$.next(true);
    return this.organizationApiService.update(id, updateReq)
      .pipe(
        finalize(() => {
          this._updatePending$.next(false);
        })
      );
  }

  changeStatus(id: number, status: OrganizationStatus): Observable<void> {
    this._deletePending$.next(true);
    return this.organizationApiService.status(id, status)
      .pipe(
        finalize(() => {
          this._deletePending$.next(false);
        }),
        tap(() => {
          this.refreshList();
        })
      );
  }

  refreshList(): void {
    this._refreshList$.next(!this._refreshList$.value);
  }

  setPagination(pagination: Pagination): void {
    this.pagination$.next(pagination);
  }

  setSorting(event: AgGridSortState[] | null): void {
    this.sortingState$.next(event);
  }

  private mapList(
    organizations: ListResponse<OrganizationListItemResponse>,
    applicationsMap: Map<number, Application>
  ): ListResponse<OrganizationListItem> {
    return {
      pagination: organizations.pagination,
      data: organizations.data.map((listItem) => ({
        ...listItem,
        applications: listItem.applicationIds?.map((id) => applicationsMap.get(id)!)
      }))
    };
  }
}
