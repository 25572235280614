<ng-container *ngxPermissionsOnly="[userRole.APS_ADMIN]; else gridWithoutDblClickSupport">
  <aps-ag-grid-wrapper
    [columnDefs]="columnDefs"
    [pageSize]="pageSize"
    [gridOptions]="gridOptions"
    [state]="gridState"
    (sortChanged)="onSortChanged($event)"
    (rowSingleClicked)="onRowClicked($event)"
    (paginationChanged)="updatePagination($event)"
  />
</ng-container>
<ng-template #gridWithoutDblClickSupport>
  <aps-ag-grid-wrapper
    [columnDefs]="columnDefs"
    [pageSize]="pageSize"
    [gridOptions]="gridOptions"
    [state]="gridState"
    (sortChanged)="onSortChanged($event)"
    (paginationChanged)="updatePagination($event)"
  />
</ng-template>
