import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {KeycloakService} from 'keycloak-angular';

@Component({
  selector: 'aps-organization-inactive-notification',
  templateUrl: './organization-inactive-notification.component.html',
  standalone: true,
  imports: [
    MatIcon,
    MatButton
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationInactiveNotificationComponent {
  private readonly keycloakService = inject(KeycloakService);

  logout(): void {
    void this.keycloakService.logout();
  }
}
