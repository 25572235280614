import {DatePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {ValueOrDefaultPipe} from '@shared/pipes';

@Component({
  selector: 'aps-ag-cell-date',
  template: `{{ (value | date:format) | apsValueOrDefault }}`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DatePipe,
    ValueOrDefaultPipe
  ]
})
export class AgCellDateComponent implements ICellRendererAngularComp {
  value!: string | Date;
  format!: string;

  agInit(params: ICellRendererParams & { format: string }): void {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    this.format = params.format;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    return false;
  }

}
