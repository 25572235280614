import {inject, Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

import {ToastMsgType} from '@shared/models';

import {ToastMsgComponent} from '../components/toast-msg';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private readonly snackBar = inject(MatSnackBar);
  duration = 5000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  success(message: string): void {
    this.notify('info', message);
  }

  warn(message = 'Something went wrong'): void {
    this.notify('warn', message);
  }

  error(message?: string | null): void {
    this.notify('error', message ?? 'Something went wrong');
  }

  private notify(type: ToastMsgType, message: string): void {
    this.snackBar.openFromComponent(ToastMsgComponent, {
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      data: {
        message,
        type
      }
    });
  }

}
