import {Pipe, PipeTransform} from '@angular/core';

import {Maybe} from '@core/common';
import {USER_ROLES_MAP} from '@features/users/const';
import {UserDto} from '@features/users/models';

@Pipe({
  name: 'apsUserRole',
  pure: true,
  standalone: true,
})
export class UserRolePipe implements PipeTransform {
  static transform(user: Partial<UserDto>): Maybe<string> {
    const role = USER_ROLES_MAP.get(user?.role!);
    return role ?? null;
  }

  transform(user: Partial<UserDto>): Maybe<string> {
    return UserRolePipe.transform(user);
  }
}
