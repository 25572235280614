import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, catchError, finalize, ReplaySubject, take, tap, throwError} from 'rxjs';

import {Maybe} from '@core/common';
import {Country} from '@features/organizations/models';

import {CountryApiService} from './country-api.service';

@Injectable({ providedIn: 'root' })
export class CountryService {
  private readonly countryApiService = inject(CountryApiService);
  private _countries$ = new ReplaySubject<Country[]>(1);
  private _countriesMap$ = new ReplaySubject<Map<number, Country>>(1);
  private _pending$ = new BehaviorSubject<boolean>(false);
  private _error$ = new BehaviorSubject<Maybe<HttpErrorResponse>>(null);

  countries$ = this._countries$.asObservable();
  countriesMap$ = this._countriesMap$.asObservable();
  pending$ = this._pending$.asObservable();
  error$ = this._error$.asObservable();

  init(): void {
    this.fetchCountries();
  }

  private fetchCountries(): void {
    this._pending$.next(true);
    this._error$.next(null);

    this.countryApiService.getList()
      .pipe(
        take(1),
        finalize(() => {
          this._pending$.next(false);
        }),
        catchError((err: HttpErrorResponse) => {
          this._error$.next(err);
          return throwError(() => err);
        }),
        tap((res) => {
          const countriesMap = new Map<number, Country>();
          res.forEach((c) => {
            countriesMap.set(c.id, c);
          });

          this._countriesMap$.next(countriesMap);
          this._countries$.next(res);
        })
      )
      .subscribe();
  }
}
