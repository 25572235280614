export interface LocationSearchRes {
  type: string;
  licence: string;
  features: LocationFeature[];
}

export interface LocationFeature {
  type: LocationFeatureType,
  properties: {
    place_id: number;
    osm_type: LocationOsmType;
    osm_id: number;
    display_name: string;
    place_rank: number;
    category: LocationPropertyCategory;
    type: LocationPropertyType;
    importance: number;
    icon: string;
  },
  bbox: number[],
  geometry: {
    type: LocationGeometryType;
    coordinates: number[];
  }
}

export enum LocationFeatureType {
  feature = 'Feature'
}

export enum LocationOsmType {
  relation = 'Relation'
}

export enum LocationPropertyCategory {
  boundary = 'boundary'
}

export enum LocationPropertyType {
  administrative = 'administrative'
}

export enum LocationGeometryType {
  point = 'Point'
}
