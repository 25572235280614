import {MappingType, MappingTypeNumeric} from '@features/projects/models';

export const projectStringMappingTypeToNumeric = new Map<MappingType, MappingTypeNumeric>()
  .set(MappingType.notSelected, MappingTypeNumeric.notSelected)
  .set(MappingType.bluePrint, MappingTypeNumeric.blueprintAttachment)
  .set(MappingType.map, MappingTypeNumeric.geographicalReference);

export const projectNumericMappingTypeToString = new Map<MappingTypeNumeric, MappingType>()
  .set(MappingTypeNumeric.notSelected, MappingType.notSelected)
  .set(MappingTypeNumeric.blueprintAttachment, MappingType.bluePrint)
  .set(MappingTypeNumeric.geographicalReference, MappingType.map);
