import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {StatusType} from '@shared/models';

@Component({
  selector: 'aps-status',
  standalone: true,
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusComponent {
  @Input() message!: string;
  @Input() type: StatusType = 'info';
}
