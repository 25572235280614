import {inject, Injectable} from '@angular/core';

import {ApplicationService, CountryService} from '@core/services';

@Injectable({ providedIn: 'root' })
export class AppInitializerService {
  private readonly countryService = inject(CountryService);
  private readonly applicationService = inject(ApplicationService);

  init(): void {
    this.countryService.init();
    this.applicationService.init();
  }
}
