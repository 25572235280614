import {Routes} from '@angular/router';

import {AuthGuard} from '@core/guards';

import {AppComponent} from './app.component';

export const appRoutes: Routes = [
  {
    path: '', component: AppComponent, children: [
      {
        path: '',
        loadChildren: () => import('./features/sidenav/sidenav.routes').then(m => m.sidenavRoutes),
        canActivate: [AuthGuard]
      },
      { path: '**', loadChildren: () => import('./features/no-content/no-content.routes').then(m => m.noContentRoutes) }
    ]
  }
];
