import {Directive, Inject, Input, TemplateRef, ViewContainerRef} from '@angular/core';

export class LetContext<T> {
  private readonly dir: LetDirective<T>;
  constructor(dir: LetDirective<T>) {
    this.dir = dir;
  }

  get apsNgLet(): T {
    return this.dir.apsNgLet;
  }
}

/**
 * Works like *ngIf but does not have a condition
 * Use it to declare the result of pipes calculation
 * (i.e. async pipe)
 */
@Directive({
  selector: '[apsNgLet]',
  standalone: true,
})
export class LetDirective<T> {
  @Input() apsNgLet!: T;

  constructor(
    @Inject(ViewContainerRef) viewContainer: ViewContainerRef,
    @Inject(TemplateRef) templateRef: TemplateRef<LetContext<T>>
  ) {
    viewContainer.createEmbeddedView(templateRef, new LetContext<T>(this));
  }
}
