import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';

import {Country} from '@features/organizations/models';

@Injectable({ providedIn: 'root' })
export class CountryApiService {
  private readonly httpClient = inject(HttpClient);

  getList(): Observable<Country[]> {
    return this.httpClient.get<Country[]>(`${environment.baseURL}/countries`);
  }
}
