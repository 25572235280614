<mat-card class="py-1 px-3 cursor-default">
  <mat-card-header>
    <mat-card-title>
      <div class="flex items-center text-sm font-semibold mb-2">
        <span>{{ (user | apsFullName | titlecase) | apsValueOrDefault }}</span>
      </div>
    </mat-card-title>
    <mat-card-subtitle>
      <div class="flex items-center text-xs font-medium mb-2">
        <mat-icon class="size-smd mr-2" svgIcon="badge" />
        <span>{{ user | apsUserRole | apsValueOrDefault }}</span>
      </div>
    </mat-card-subtitle>
    @if (showOrganizations) {
      <mat-card-subtitle>
        <div class="flex items-center text-xs font-medium mb-2">
          <mat-icon class="size-smd mr-2" svgIcon="home-city-outline" />
          <span>{{ userOrganizations | apsValueOrDefault | apsTruncate: [40] }}</span>
        </div>
      </mat-card-subtitle>
    }
  </mat-card-header>
  <mat-card-content />
</mat-card>
