import {HttpClient, HttpParams} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';

import {GpsCoordinates, ListResponse, Maybe, Pagination} from '@core/common';
import {
  EventReportProject,
  EventReportProjectCreateReq,
  EventReportProjectListEntry,
  EventReportProjectUpdateReq
} from '@features/projects/models';
import {AgGridSort, AgGridSortState, ProjectFilter} from '@shared/models';

@Injectable({ providedIn: 'root' })
export class EventReportingApiService {
  private readonly httpClient = inject(HttpClient);

  getProjects(pagination: Pagination, sorting: Maybe<AgGridSortState[]> = null, filter: Maybe<ProjectFilter> = null): Observable<ListResponse<EventReportProjectListEntry>> {
    let params = new HttpParams({
      fromObject: {
        ...(filter?.name ? { searchName: filter.name } : null),
        ...(filter?.isActive ? { isActive: filter.isActive } : null),
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize
      }
    });

    if (sorting?.length) {
      params = sorting.reduce((parms, el) => {
        const sortingOrder = el.order === AgGridSort.DESC ? 'OrderByDescending' : 'OrderBy';
        return parms.append('PropertyNames', `${this.mapSortPropertyToDtoProperty(el.propertyName!)},${sortingOrder}`);
      }, params);
    }

    return this.httpClient.get<ListResponse<EventReportProjectListEntry>>(`${environment.baseURL}/eventreporting/projects`, { params });
  }

  getById(id: number): Observable<EventReportProject> {
    return this.httpClient.get<EventReportProject>(`${environment.baseURL}/eventreporting/projects/${id}`);
  }

  updateStatus(id: number, isActive: boolean): Observable<void> {
    return this.httpClient.patch<void>(`${environment.baseURL}/eventreporting/projects/${id}/status`, { isActive });
  }

  create(project: EventReportProjectCreateReq): Observable<number> {
    return this.httpClient.post<number>(`${environment.baseURL}/eventreporting/projects`, project);
  }

  update(project: EventReportProjectUpdateReq): Observable<EventReportProject> {
    return this.httpClient.patch<EventReportProject>(`${environment.baseURL}/eventreporting/projects/${project.id}`, project);
  }

  searchByName(name: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.baseURL}/eventreporting/projects/${name}/exists`);
  }

  getProjectFileByName(projectId: number, fileName: string): Observable<Blob> {
    return this.httpClient.get(`${environment.baseURL}/eventreporting/projects/${projectId}/files/${fileName}`, { responseType: 'blob' });
  }

  uploadBlueprintFile(projectId: number, file: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<string>(`${environment.baseURL}/eventreporting/projects/${projectId}/files/blueprint`, formData);
  }

  uploadFiberRouteFile(projectId: number, file: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.post<string>(`${environment.baseURL}/eventreporting/projects/${projectId}/files/fiberroute`, formData);
  }

  addProjectGpsLocation(projectId: number, gpsLocation: GpsCoordinates): Observable<number> {
    return this.httpClient.post<number>(`${environment.baseURL}/eventreporting/projects/${projectId}/gpslocation/coordinates`, {
      ...gpsLocation
    });
  }

  private mapSortPropertyToDtoProperty(propertyName: string): string {
    if (propertyName === 'organization.name') {
      return 'organization';
    }
    return propertyName;
  }
}
