import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'apsTruncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  private readonly defaultLimit = 20;

  transform(value: string, args?: [number, string?]): string {
    const limit = args?.length! > 0 ? parseInt(String(args![0]), 10) : this.defaultLimit;
    const trail = args?.length! > 1 ? args![1] : '...';
    return value?.length > limit ? `${value.substring(0, limit)}${trail}` : value;
  }
}

