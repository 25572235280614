<div class="p-6">
  <div class="bg-warning-tint flex items-center mb-6 p-4">
    <mat-icon class="color-warning size-lg" svgIcon="alert-circle-outline" />
    <div class="ml-4">
      <p>Organization was deactivated.</p>
      <p>Please, contact your Administrator</p>
    </div>
  </div>

  <button mat-flat-button color="primary" (click)="logout()">Log Out</button>
</div>
