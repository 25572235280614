import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {UserCheckResult} from '@features/users/models';
import {UserApiService} from '@features/users/services';

@Injectable({ providedIn: 'root' })
export class InputTechniciansService {
  private readonly userApiService = inject(UserApiService);

  foundUsersMap: Record<string, { email: string }> = {};

  checkUserByEmail(email: string): Observable<UserCheckResult> {
    return this.userApiService.checkUser(email).pipe(
      tap((res) => {
        if (res.isUserExist) {
          this.foundUsersMap[email] = { email };
        }
      })
    );
  }
}
