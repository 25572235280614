import {DecimalPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {ValueOrDefaultPipe} from '@shared/pipes';

@Component({
  selector: 'aps-ag-cell-distance',
  template: `
    <div class="flex items-center">
      <mat-icon class="color-medium size-sm mr-2" svgIcon="distance" />
      <div>
        {{ (value | number) | apsValueOrDefault }}
        {{ kmIndicator }}
      </div>
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DecimalPipe,
    ValueOrDefaultPipe,
    MatIcon
  ],
  providers: []
})
export class AgCellDistanceComponent implements ICellRendererAngularComp {
  value!: number;
  kmIndicator = '';

  agInit(params: ICellRendererParams): void {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    this.setKmIndicator();
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    this.setKmIndicator();
    return false;
  }

  private setKmIndicator(): void {
    if (this.value || this.value === 0) {
      this.kmIndicator = 'km';
    } else {
      this.kmIndicator = '';
    }
  }

}
