import {ChangeDetectionStrategy, Component, inject, Input, TemplateRef} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {catchError, EMPTY, switchMap, tap} from 'rxjs';

import {DEFAULT_MODAL_WIDTH} from '@core/common';
import {ProjectService} from '@features/projects/services';
import {NotificationService} from '@shared/services';

@Component({
  selector: 'aps-project-create-btn',
  templateUrl: './project-create-btn.component.html',
  standalone: true,
  imports: [
    MatIcon,
    MatButton
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectCreateBtnComponent {
  private readonly projectService = inject(ProjectService);
  private readonly matDialog = inject(MatDialog);
  private readonly notification = inject(NotificationService);
  @Input() templateRef!: TemplateRef<unknown>;

  showDialog(): void {
    this.openCreateDialog();
  }

  private openCreateDialog(): void {
    this.matDialog
      .open(this.templateRef, {
        width: DEFAULT_MODAL_WIDTH,
        closeOnNavigation: true
      })
      .afterClosed()
      .pipe(
        tap(result => {
          if (result.openOnEdit) {
            this.openProjectEditModal(result.projectId as number);
          }
        })
      ).subscribe();
  }

  private openProjectEditModal(projectId: number): void {
    this.projectService.getById(projectId).pipe(
      switchMap((project) => {
        return this.matDialog.open(this.templateRef, {
          width: DEFAULT_MODAL_WIDTH,
          data: project,
          closeOnNavigation: true
        }).afterClosed()
          .pipe(
            tap(result => {
              if (result.openOnEdit) {
                this.openProjectEditModal(result.projectId as number);
              }
            })
          )
      }),
      catchError((err: Error) => {
        this.notification.error(err?.message ?? 'Failed to fetch project by id');
        return EMPTY;
      })
    ).subscribe();
  }
}
