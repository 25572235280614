import {DOCUMENT} from '@angular/common';
import {inject, InjectionToken} from '@angular/core';

import {Pagination} from './common.types';

export const INITIAL_PAGINATION_CONFIG: Pagination = {
  pageIndex: 0,
  pageSize: 50
};

export const DEFAULT_PAGE_SIZES = [5, 10, 20, 50, 100];

export const DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZES[3];
export const DEFAULT_PAGE_SIZE_SMALL = DEFAULT_PAGE_SIZES[1];

export const DEFAULT_MODAL_WIDTH = '1024px';

export const WINDOW = new InjectionToken<Window>(
  'An abstraction over global window object',
  {
    factory: () => inject(DOCUMENT).defaultView!
  },
);
