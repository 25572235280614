<div>
  <h2 mat-dialog-title>{{ confirmActionDialogData?.title }}</h2>
  <div mat-dialog-content>
    <p>{{ confirmActionDialogData?.description }}</p>
  </div>
  <div mat-dialog-actions>
    <div class="left-side">
      <button mat-flat-button
              color="primary"
              class="mr-2"
              (click)="confirm()">
        Confirm
      </button>
      <button mat-stroked-button
              (click)="close()">Cancel
      </button>
    </div>
  </div>
</div>
