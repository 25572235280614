export const environment = {
  production: false,
  blueprintMaxFileSizeMb: 10,
  allowedFiberRouteFilesExtensions: ['.kml', '.kmz', '.csv'],
  baseURL: 'https://dev.themis.apsensing.eu/api/v1',
  envURL: 'https://dev.themis.apsensing.eu',
  mapBaseUrl: 'https://map.themis.apsensing.eu',
  mapAccessToken: 'pk.eyJ1IjoiYXBzZW5zaW5nLWNvIiwiYSI6ImNsZTQybnphNjA4cTEzbm1yb2dvbjQwbzQifQ.37oR4MnP9AvC8pnVVgxQxA',
  keycloakRealm: 'aps',
  keycloakClientId: 'aps-admin',
  keycloakUrl: 'https://signin.dev.themis.apsensing.eu/',
  mobAppStoreLink: 'https://apps.apple.com/us/app/ap-sensing-themis/id6446323844',
  mobAppGooglePlayLink: 'https://play.google.com/store/apps/details?id=com.apsensing.sw.themis.mobile.uno',
  mobileAppWebUrl: 'https://dev-mobile.themis.apsensing.eu',
  privacyPolicyUrl: 'https://dev.themis.apsensing.eu/api/static/privacy-policy/'
};
