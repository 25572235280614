import {DecimalPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {ValueOrDefaultPipe} from '@shared/pipes';

@Component({
  selector: 'aps-ag-cell-number',
  template: `{{(value | number: format) | apsValueOrDefault}}`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DecimalPipe,
    ValueOrDefaultPipe
  ]
})
export class AgCellNumberComponent implements ICellRendererAngularComp {
  value!: number;
  format!: string;

  agInit(params: ICellRendererParams): void {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    this.format = params.colDef!.cellRendererParams;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    return false;
  }
}
