<label>User</label>
<mat-form-field class="w-full" appearance="outline" floatLabel="always">
  <mat-icon matPrefix class="color-medium" svgIcon="account-outline" />
  <mat-select [(ngModel)]="value"
              placeholder="Select User"
              [disabled]="disabled"
              [compareWith]="comparePrimaryContacts"
              [multiple]="false">
    <mat-select-trigger>
      {{ value | apsFullName }}
    </mat-select-trigger>

    <mat-option style="display: none" [value]="value">
      {{ value | apsFullName }}
    </mat-option>

    <mat-option>
      <ngx-mat-select-search [formControl]="primaryContactFilterCtrl"
                             [closeSvgIcon]="'close'"
                             [noEntriesFoundLabel]="'Nothing found'"
                             [hideClearSearchButton]="true"
                             placeholderLabel="Search by Username" />
    </mat-option>
    @for (user of filteredPrimaryContacts$ | async; track user.id) {
      <mat-option [value]="user">{{ user.firstName }}</mat-option>
    }
  </mat-select>
</mat-form-field>
