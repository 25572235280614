import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {ValueOrDefaultPipe} from '@shared/pipes';

@Component({
  selector: 'aps-ag-cell-string',
  template: `{{ value  | apsValueOrDefault }}`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ValueOrDefaultPipe]
})
export class AgCellStringComponent implements ICellRendererAngularComp {
  value!: string;

  agInit(params: ICellRendererParams): void {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    return false;
  }

}
