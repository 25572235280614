import {AbstractControl} from '@angular/forms';
import {catchError, map, of, switchMap, timer} from 'rxjs';

import {BaseEntity, isArraysAreEqual, Maybe, RawFormValue} from '@core/common';
import {EventReportingService} from '@features/event-reporting/services';
import {OrganizationListItemResponse} from '@features/organizations/models';
import {projectNumericMappingTypeToString, projectStringMappingTypeToNumeric} from '@features/projects/const';
import {
  EventReportProject,
  EventReportProjectCreateReq,
  EventReportProjectFormRawValue,
  EventReportProjectUpdateReq,
  MappingType,
  Project, ProjectCreateReq, ProjectForm, ProjectUpdateReq
} from '@features/projects/models';
import {ProjectService} from '@features/projects/services';
import {UserBase} from '@features/users/models';


export const isProjectsAreEqual = (p1: Partial<Project>, p2: Partial<RawFormValue<ProjectForm>>): boolean =>
  (p1?.name === p2?.name)
  && (p1?.progress === p2?.progress)
  && (p1?.organization?.id === p2?.organization)
  && (p1?.ocNumber === p2?.ocNumber)
  && (p1?.poNumber === p2?.poNumber)
  && (p1?.distance === p2?.distance)
  && (p1?.reportFileName === p2?.reportFileName)
  && (p1?.routeValidation === p2?.routeValidation)
  && ((p1?.positiveSectionLengthTolerance ?? 0) === (p2?.positiveSectionLengthTolerance ?? 0))
  && (Math.abs(p1?.negativeSectionLengthTolerance ?? 0) === Math.abs(p2?.negativeSectionLengthTolerance ?? 0))
  && isArraysAreEqual(p1?.serialNumbers!, p2?.serialNumbers!)
  && isTechniciansAreEqual(p1?.technicians!, p2?.technicians!)
  && (p1?.description === p2?.description)
  && (p1?.address === p2?.address)
  && (p1?.mappingType === p2?.mappingType)
  && (p1?.latitude === p2?.latitude)
  && (p1?.apsComment === p2?.apsComment)
  && (p1?.longitude === p2?.longitude);


export const isEventReportProjectsAreEqual = (p1: Partial<EventReportProject>, p2: Partial<EventReportProjectFormRawValue>): boolean =>
  (p1?.name === p2?.name) &&
  (p1?.description === p2?.description) &&
  (p1?.organization?.id === p2?.organization) &&
  (p1?.locationAddress === p2?.address) &&
  (p1?.gpsLocation?.latitude === p2?.latitude) &&
  (p1?.gpsLocation?.longitude === p2?.longitude) &&
  (p1?.fiberRouteFileName === p2?.fiberRouteFileName) &&
  isTechniciansAreEqual(p1?.assignedTechnicians!, p2?.technicians!, true);

export const isTechniciansAreEqual = (t1: UserBase[], t2: UserBase[], useEmailComparation = false): boolean => {
  if (!t1 && !t2) {
    return true;
  }

  if (!t1?.length && !t2?.length) {
    return true;
  }

  if (t1.length !== t2.length) {
    return false;
  }

  const usersMap = new Set();

  t2.forEach(obj => {
    usersMap.add(obj.userName);
  });

  for (const user of t1) {
    if (!usersMap.has(useEmailComparation ? user.email : user.userName)) {
      return false;
    }
  }

  return true;
};

export class ValidateProjectNameNotTaken {
  static createValidator(projectService: ProjectService, currentProject: Maybe<Project> = null) {
    return (control: AbstractControl<string>) => {
      if (currentProject?.name === control.value || !control.value?.trim()) {
        return of(null);
      }

      return timer(400).pipe(
        switchMap(() => projectService.searchByName(control.value)),
        map((result) => {
          if (result?.data?.length) {
            const exactMatches = result.data.filter(v => v.name === control.value);
            if (exactMatches.length) {
              return { alreadyExists: true };
            }
          }
          return null;
        }),
        catchError(() => of(null))
      );
    };
  }
}


export class ValidateEventReportProjectNameNotTaken {
  static createValidator(projectService: EventReportingService, currentProject: Maybe<EventReportProject> = null) {
    return (control: AbstractControl<string>) => {
      if (currentProject?.name === control.value || !control.value?.trim()) {
        return of(null);
      }

      return timer(400).pipe(
        switchMap(() => projectService.searchByName(control.value)),
        map((isExist) => isExist ? { alreadyExists: true } : null),
        catchError(() => of(null))
      );
    };
  }
}

export const mapFormValueToProjectCreateDto = (formValue: RawFormValue<ProjectForm>): ProjectCreateReq => {
  return {
    organization: { id: formValue.organization! },
    technicians: formValue.technicians?.map(v => ({ email: v.email! })),
    name: formValue.name,
    routeValidation: formValue.routeValidation,
    positiveSectionLengthTolerance: formValue.positiveSectionLengthTolerance ? formValue.positiveSectionLengthTolerance : undefined,
    negativeSectionLengthTolerance: formValue.negativeSectionLengthTolerance ? -formValue.negativeSectionLengthTolerance : undefined,
    ocNumber: formValue.ocNumber,
    poNumber: formValue.poNumber,
    distance: formValue.distance!,
    serialNumbers: (formValue.serialNumbers ?? []).filter(Boolean),
    description: formValue.description,
    address: formValue.address,
    longitude: formValue.longitude!,
    latitude: formValue.latitude!,
    leftTopLatitude: formValue.leftTopLatitude!,
    leftTopLongitude: formValue.leftTopLongitude!,
    rightBottomLatitude: formValue.rightBottomLatitude!,
    rightBottomLongitude: formValue.rightBottomLongitude!,
    zoom: formValue.zoom!,
    mapType: formValue.mapType!,
    apsComment: formValue.apsComment,
    photo: formValue.photo,
    progress: formValue.progress!,
    mappingType: formValue.mappingType,
  };
}

export const mapFormValueToProjectUpdateDto = (projectId: number, formValue: RawFormValue<ProjectForm>): ProjectUpdateReq => {
  return {
    id: projectId,
    ...mapFormValueToProjectCreateDto(formValue),
  };
}

export const mapProjectToFormRawValue = (project: Project): RawFormValue<ProjectForm> => {
  return {
    ...project,
    id: project?.id,
    name: project?.name,
    ocNumber: project?.ocNumber,
    poNumber: project?.poNumber,
    distance: project?.distance,
    serialNumbers: project?.serialNumbers ?? [],
    description: project?.description,
    apsComment: project?.apsComment,
    technicians: project?.technicians ?? [],
    address: project?.address,
    latitude: project?.latitude,
    longitude: project?.longitude,
    mappingType: project?.mappingType ?? MappingType.notSelected,
    photo: project?.photo,
    progress: project?.progress,
    webhookUrl: project?.webhookUrl,
    leftTopLatitude: project?.leftTopLatitude ?? null,
    leftTopLongitude: project?.leftTopLongitude ?? null,
    rightBottomLatitude: project?.rightBottomLatitude ?? null,
    rightBottomLongitude: project?.rightBottomLongitude ?? null,
    zoom: project?.zoom ?? null,
    routeValidation: project?.routeValidation,
    mapType: project?.mapType!,
    negativeSectionLengthTolerance: project?.negativeSectionLengthTolerance ? Math.abs(project.negativeSectionLengthTolerance) : null,
    positiveSectionLengthTolerance: project?.positiveSectionLengthTolerance ? project.positiveSectionLengthTolerance : null,
    fiberRouteFile: null,
    organization: project?.organization?.id
  };
};

export const mapErProjectDtoFormValue = (project: EventReportProject): EventReportProjectFormRawValue => {
  return {
    organization: project?.organization?.id,
    id: project.id,
    name: project.name,
    description: project.description,
    address: project.locationAddress,
    mappingType: projectNumericMappingTypeToString.get(project.mapType)!,
    technicians: project.assignedTechnicians.map(v => ({ userName: v.email, id: v.id })),
    latitude: project.gpsLocation?.latitude!,
    longitude: project.gpsLocation?.longitude!,
    fiberRouteFileName: project.fiberRouteFileName
  };
};

export const mapRawValueToEventReportProjectCreateReq = (project: EventReportProjectFormRawValue): EventReportProjectCreateReq => {
  return {
    name: project.name,
    description: project.description,
    organizationId: project.organization,
    mapType: projectStringMappingTypeToNumeric.get(project.mappingType)!,
    locationAddress: project.address,
    assignedTechnicians: project.technicians.map(v => ({ email: v.userName! })),
    ...(project.latitude && project.longitude ? {
      gpsLocation: {
        longitude: project.longitude,
        latitude: project.latitude,
        altitude: null
      }
    } : null)
  } as EventReportProjectCreateReq;
};

export const mapRawValueToEventReportProjectUpdateReq = (formProjectData: EventReportProjectFormRawValue, project: EventReportProject): EventReportProjectUpdateReq => {
  const projectCreateReq = mapRawValueToEventReportProjectCreateReq(formProjectData);

  return {
    ...projectCreateReq,
    gpsLocation: {
      ...projectCreateReq.gpsLocation,
      id: project.gpsLocation?.id!
    },
    id: formProjectData.id,
    isActive: project.isActive
  } as EventReportProjectUpdateReq;
};

export const mapProjectToEventReportProjectUpdateReq = (project: EventReportProject): EventReportProjectUpdateReq => {
  return {
    id: project.id,
    name: project.name,
    description: project.description,
    gpsLocation: project.gpsLocation!,
    organizationId: project.organization.id,
    isActive: project.isActive,
    mapType: project.mapType,
    locationAddress: project.locationAddress,
    assignedTechnicians: project.assignedTechnicians?.map(v => ({ email: v.email! })) || []
  };
};

export const mapProjectOrganizationsToDto = (organizations: OrganizationListItemResponse[]): BaseEntity<number>[] => {
  return (organizations || [])
    .map(({ id, organizationName }) => ({ id, name: organizationName } as BaseEntity<number>));
};

export const ask2Validator = (control: AbstractControl<string>) => {
  // eslint-disable-next-line no-control-regex
  const re = new RegExp('[\x00-\x7F]');

  if (!control.value) {
    return null;
  }

  if (re.test(control.value)) {
    return null;
  }
  return { invalidSymbols: true };
};

export const mappingTypeValidator = (control: AbstractControl) => {
  if (!control.value) {
    return null;
  }

  if (control.value !== MappingType.notSelected) {
    return null;
  }
  return { invalidMappingType: true };
};

