import {ColDef, ValueFormatterParams} from 'ag-grid-community';

import {Project} from '@features/projects/models';
import {
  AgCellDateComponent,
  AgCellDistanceComponent,
  AgCellOcNumberComponent,
  AgCellPoiComponent,
  AgCellProjectNameComponent,
  AgCellStatusComponent,
  AgCellStringComponent,
  AgCellToolsComponent,
  AgCellUserListComponent
} from '@shared/components/ag-cells';

export const PROJECTS_LIST_COLUMN_DEFS: ColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    cellRenderer: AgCellProjectNameComponent,
    sortable: true,
    comparator: () => 0,
    minWidth: 400,
    tooltipField: 'name',
    cellClass: 'cell-overflow-ellipsis'
  },
  {
    field: 'ocNumber',
    headerName: 'OC Number',
    cellRenderer: AgCellOcNumberComponent,
    minWidth: 120,
    resizable: true,
    sortable: true,
    comparator: () => 0,
    tooltipField: 'ocNumber',
    cellClass: 'cell-overflow-ellipsis'
  },
  {
    field: 'organization.name',
    headerName: 'Organization',
    cellRenderer: AgCellStringComponent,
    minWidth: 150,
    sortable: true,
    comparator: () => 0,
    resizable: true,
    tooltipField: 'organization.name',
    cellClass: 'cell-overflow-ellipsis'
  },
  {
    field: 'address',
    headerName: 'Location',
    cellRenderer: AgCellStringComponent,
    minWidth: 100,
    sortable: true,
    comparator: () => 0,
    resizable: true,
    tooltipField: 'address',
    cellClass: 'cell-overflow-ellipsis'
  },
  {
    field: 'technicians',
    headerName: 'Assigned To',
    sortable: true,
    comparator: () => 0,
    cellClass: 'bg-light left-border cell-overflow-ellipsis',
    cellRenderer: AgCellUserListComponent,
    minWidth: 170,
    resizable: true
  },
  {
    field: 'modifiedOn',
    headerName: 'Modified',
    sortable: true,
    comparator: () => 0,
    cellRenderer: AgCellDateComponent,
    cellClass: 'bg-light',
    minWidth: 170,
    cellRendererParams: { format: 'MMM d y, hh:mm a' }
  },
  {
    field: 'distance',
    cellRenderer: AgCellDistanceComponent,
    cellClass: 'bg-light',
    minWidth: 100,
    sortable: true,
    comparator: () => 0,
    resizable: true
  },
  {
    field: 'poi',
    headerName: 'POI',
    sortable: true,
    comparator: () => 0,
    cellRenderer: AgCellPoiComponent,
    cellClass: 'bg-light',
    minWidth: 70
  },
  {
    field: 'serialNumbers',
    headerName: 'Tools',
    sortable: true,
    comparator: () => 0,
    cellRenderer: AgCellToolsComponent,
    cellClass: 'bg-light cell-overflow-ellipsis',
    minWidth: 120,
    resizable: true
  },
  {
    field: 'isActive',
    sortable: true,
    comparator: () => 0,
    valueFormatter: (params: ValueFormatterParams<Project, boolean>) => {
      return params.value ? 'ACTIVE' : 'INACTIVE';
    },
    cellRenderer: AgCellStatusComponent,
    minWidth: 80
  }
];
