<ng-container *apsNgLet="(pending$ | async) as pending">
  @if (organization?.status === organizationStatus.active) {
    <button mat-stroked-button
            color="warn"
            class="w-100"
            [apsBtnPending]="pending"
            [disabled]="!organization"
            (click)="deactivate()">
      Deactivate Organization
    </button>
  } @else {
    <button mat-stroked-button
            color="accent"
            class="w-100"
            [apsBtnPending]="pending"
            [disabled]="!organization"
            (click)="activate()">
      Activate Organization
    </button>
  }
</ng-container>
