export const isArraysAreEqual = <T>(arr1: T[], arr2: T[]): boolean => {
  if (!arr1 && !arr2) {
    return true;
  }

  if (!arr1?.length && !arr2?.length) {
    return true;
  }

  if (arr1?.length && arr2?.length) {
    return arr1.length === arr2.length
      && arr1.every((value) => value === arr2.find(d => d === value));
  }

  return false;
};

/**
 * This method works correctly only with POJO!
 */
export const hasDtoChanged = (dto1: Record<string, any>, dto2: Record<string, any>): boolean => {
  const keys1 = Object.keys(dto1);
  const keys2 = Object.keys(dto2);
  if (keys1.length !== keys2.length) {
    return true;
  }
  for (let i = 0; i < keys1.length; i++) {
    const key1 = keys1[i];
    const key2 = keys2[i];

    if (key1 !== key2 || dto1[key1] !== dto2[key2]) {
      return true;
    }
  }
  return false;
};
