import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';

import {BtnPendingDirective} from '@shared/directives';

@Component({
  selector: 'aps-entity-create-dialog',
  templateUrl: './entity-create-dialog.component.html',
  standalone: true,
  imports: [
    MatIconButton,
    MatDialogClose,
    MatIcon,
    MatDialogTitle,
    MatButton,
    MatDialogActions,
    MatDialogContent,
    BtnPendingDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityCreateDialogComponent {
  @Input() icon!: string;

  @Input() title!: string;

  @Input() submitBtnText!: string;

  @Input() submitBtnDisabled = false;

  @Input() cancelDtnDisabled = false;

  @Input() apsBtnPending = false;

  @Output() submitted = new EventEmitter<void>();
}
