import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'apsValueOrDefault',
  pure: true,
  standalone: true,
})
export class ValueOrDefaultPipe implements PipeTransform {
  transform<T>(value: T, defaultValue = 'N/A'): T | string {

    if (value === 0) {
      return value;
    }

    return value || defaultValue;
  }
}
