<div class="bg-light p-6 text-sm rounded">

  <div class="flex flex-row w-full mb-2">
    <div class="basis-32">Created By</div>
    <div class="basis-auto flex items-center">
      <div class="font-medium">{{ createdBy | apsFullName | apsValueOrDefault }}</div>
      <div class="ml-10 text-xs">{{ createdBy?.role | apsValueOrDefault }}</div>
    </div>
  </div>

  <div class="flex flex-row w-full mb-2">
    <div class="basis-32">Created</div>
    <div class="basis-auto flex items-center">
      <mat-icon class="color-medium size-sm" svgIcon="calendar-range" />
      <div class="ml-2 font-medium">{{ createdDate | date:dateFormat }}</div>
    </div>
  </div>

  <div class="flex flex-row w-full mb-2">
    <div class="basis-32">Modified By</div>
    <div class="basis-auto flex items-center">
      <div class="font-medium">{{ modifiedBy | apsFullName | apsValueOrDefault }}</div>
      <div class="ml-10 text-xs">{{ modifiedBy?.role }}</div>
    </div>
  </div>

  <div class="flex flex-row w-full">
    <div class="basis-32">Modified</div>
    <div class="basis-auto flex items-center">
      <mat-icon class="color-medium size-sm" svgIcon="calendar-range" />
      <div class="ml-2 font-medium">{{ lastUpdatedDate | date: dateFormat | apsValueOrDefault }}</div>
    </div>
  </div>
</div>
