import {AfterViewInit, Directive, EventEmitter, inject, Input, NgZone, OnInit, Output} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, filter, fromEvent, tap} from 'rxjs';

const SELECT_ITEM_HEIGHT_EM = 3;

@UntilDestroy()
@Directive({
  selector: '[apsMatSelectInfiniteScroll]',
  standalone: true,
})
export class MatSelectInfiniteScrollDirective implements OnInit, AfterViewInit {
  private readonly matSelect = inject(MatSelect);
  private readonly ngZone = inject(NgZone);
  private panel!: Element;
  private thrPx = 0;
  private thrPc = 0;
  private singleOptionHeight = SELECT_ITEM_HEIGHT_EM;

  @Input() threshold = '15%';
  @Input() debounceTime = 150;
  @Input() complete!: boolean;
  @Output() infiniteScroll = new EventEmitter<void>();

  ngOnInit(): void {
    this.evaluateThreshold();
  }

  ngAfterViewInit(): void {
    this.matSelect.openedChange.pipe(
      untilDestroyed(this),
      filter(Boolean),
      tap(() => {
        this.panel = this.matSelect.panel.nativeElement;
        this.singleOptionHeight = this.getSelectItemHeightPx();
        this.registerScrollListener();
      })
    ).subscribe();
  }

  evaluateThreshold(): void {
    if (this.threshold.lastIndexOf('%') > -1) {
      this.thrPx = 0;
      this.thrPc = (parseFloat(this.threshold) / 100);

    } else {
      this.thrPx = parseFloat(this.threshold);
      this.thrPc = 0;
    }
  }

  registerScrollListener() {
    fromEvent(this.panel, 'scroll').pipe(
      untilDestroyed(this),
      debounceTime(this.debounceTime),
      tap((event) => {
        this.handleScrollEvent(event);
      })
    ).subscribe();
  }

  handleScrollEvent(event: any) {
    this.ngZone.runOutsideAngular(() => {
      if (this.complete) {
        return;
      }
      const countOfRenderedOptions = this.matSelect.options.length;
      const infiniteScrollDistance = this.singleOptionHeight * countOfRenderedOptions;
      const threshold = this.thrPc !== 0 ? (infiniteScrollDistance * this.thrPc) : this.thrPx;

      const scrolledDistance = this.panel.clientHeight + event.target.scrollTop;

      if ((scrolledDistance + threshold) >= infiniteScrollDistance) {
        this.ngZone.run(() => this.infiniteScroll.emit());
      }
    });
  }

  getSelectItemHeightPx(): number {
    return parseFloat(getComputedStyle(this.panel).fontSize) * SELECT_ITEM_HEIGHT_EM;
  }
}
