import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion';
import {MatError, MatFormField} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatOption, MatSelect} from '@angular/material/select';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BehaviorSubject, catchError, of, tap} from 'rxjs';

import {DialogMode, Maybe} from '@core/common';
import {ApplicationService, CountryService} from '@core/services';
import {OrganizationCreateUpdateReq, OrganizationResponse, OrganizationStatus} from '@features/organizations/models';
import {OrganizationApiService, OrganizationService} from '@features/organizations/services';
import {isOrganizationsAreEqual, ValidateNameNotTaken, ValidateRefCodeNotTaken} from '@features/organizations/utils';
import {EntityCreateDialogComponent, UserCardComponent} from '@shared/components';
import {NotificationService} from '@shared/services';

import {OrganizationDeactivateComponent} from '../organization-deactivate';


interface FormType {
  organizationName: FormControl<string>;
  applicationIds: FormControl<number[]>;
  referenceCode: FormControl<string>;
  countryId: FormControl<Maybe<number>>;
  location: FormControl<string>;
  comment: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'aps-organization-create',
  templateUrl: './organization-create.component.html',
  styleUrls: ['./organization-create.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    EntityCreateDialogComponent,
    UserCardComponent,
    OrganizationDeactivateComponent,
    MatProgressSpinner,
    AsyncPipe,
    MatFormField,
    MatIcon,
    MatInput,
    MatSelect,
    MatError,
    MatOption,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelHeader
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationCreateComponent implements OnInit {
  private readonly dialogRef = inject(MatDialogRef<OrganizationCreateComponent>);
  private readonly fb = inject(NonNullableFormBuilder);
  private readonly organizationService = inject(OrganizationService);
  private readonly notificationService = inject(NotificationService);
  private readonly countryService = inject(CountryService);
  private readonly organizationApiService = inject(OrganizationApiService);
  private readonly applicationService = inject(ApplicationService);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  organization: OrganizationResponse = inject(MAT_DIALOG_DATA);
  mode: DialogMode = 'create';
  form!: FormGroup<FormType>;
  createPending$ = this.organizationService.createPending$;
  updatePending$ = this.organizationService.updatePending$;
  submitBtnDisabled = true;
  countries$ = this.countryService.countries$;
  applications$ = this.applicationService.applications$;
  changed$ = new BehaviorSubject<boolean>(false);

  readonly organizationNameMinLength = 2;
  readonly organizationNameMaxLength = 256;
  readonly referenceCodeMaxLength = 256;
  readonly locationMaxLength = 256;
  readonly commentMaxLength = 512;

  get isUpdateMode(): boolean {
    return this.mode === 'update';
  }

  ngOnInit(): void {
    this.form = this.getForm();
    this.form.patchValue(this.organization);
    this.detectMode();
    this.listenFormChanges();
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this.submitOrganization();
  }

  submitOrganization(): void {
    this.form.disable();
    const createUpdateDto = this.getOrganizationCreateUpdateDto();
    const operationToPerform$ = this.mode === 'create' ?
      this.organizationService.create(createUpdateDto) :
      this.organizationService.update(this.organization.id, createUpdateDto);

    operationToPerform$
      .pipe(
        tap((orgId) => {
          this.close({ ...createUpdateDto, id: this.mode === 'create' ? orgId : this.organization.id });
          this.notificationService.success(`${this.mode === 'create' ? 'Created' : 'Updated'} successfully`);
        }),
        catchError(() => {
          this.enableForm();
          this.notificationService.error('Something went wrong');
          return of(null);
        }),
      )
      .subscribe();
  }

  close(organization?: Partial<OrganizationCreateUpdateReq & { id: number }>): void {
    this.dialogRef.close({ organization });
  }

  onOrganizationStatusChanged(organization: OrganizationResponse): void {
    this.organization.status = organization.status;
    if (organization?.status === OrganizationStatus.active) {
      this.enableForm();
    } else {
      this.form.disable();
    }
  }

  private getForm(): FormGroup<FormType> {
    return this.fb.group<FormType>({
      organizationName: this.fb.control('',
        [
          Validators.required,
          Validators.minLength(this.organizationNameMinLength),
          Validators.maxLength(this.organizationNameMaxLength)
        ],
        [ValidateNameNotTaken.createValidator(this.organizationApiService, this.organization)]
      ),
      applicationIds: this.fb.control([]),
      referenceCode: this.fb.control('',
        [Validators.required, Validators.maxLength(this.referenceCodeMaxLength)],
        [ValidateRefCodeNotTaken.createValidator(this.organizationApiService, this.organization)]
      ),
      countryId: this.fb.control(null),
      location: this.fb.control('', [Validators.maxLength(this.locationMaxLength)]),
      comment: this.fb.control('', [Validators.maxLength(this.commentMaxLength)])
    });
  }

  private listenFormChanges(): void {
    this.changed$.next(!isOrganizationsAreEqual(this.organization, this.getOrganizationCreateUpdateDto()));
    this.form.valueChanges
      .pipe(
        tap(() => {
          this.changed$.next(!isOrganizationsAreEqual(this.organization, this.getOrganizationCreateUpdateDto()));
          this.checkSubmitBtnDisabled();
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.form.statusChanges
      .pipe(
        tap(() => {
          this.checkSubmitBtnDisabled();
          this.changeDetectorRef.markForCheck();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private detectMode(): void {
    if (this.organization?.id) {
      this.mode = 'update';
      this.form.controls.referenceCode.disable();

      if (this.organization?.status === OrganizationStatus.active) {
        this.enableForm();
      } else {
        this.form.disable();
      }
    }
  }

  private enableForm(): void {
    this.form.enable();
    if (this.mode === 'update') {
      this.form.controls.referenceCode.disable();
    }
  }

  private getOrganizationCreateUpdateDto(): OrganizationCreateUpdateReq {
    return {
      ...this.form.getRawValue()
    } as OrganizationCreateUpdateReq;
  }

  private checkSubmitBtnDisabled(): void {
    this.submitBtnDisabled = this.form.invalid
      || !this.changed$.value
      || this.form.controls.organizationName.pending
      || this.form.controls.referenceCode.pending
      || this.organization?.status === OrganizationStatus.inactive;
  }
}
