import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';

@Component({
  selector: 'aps-confirm-action-dialog',
  templateUrl: './confirm-action-dialog.component.html',
  standalone: true,
  imports: [
    FormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmActionDialogComponent {
  private readonly dialogRef = inject(MatDialogRef<ConfirmActionDialogComponent>);
  readonly confirmActionDialogData = inject(MAT_DIALOG_DATA);

  confirm(): void {
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close();
  }
}
