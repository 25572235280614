import {CommonModule} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {AgGridModule} from 'ag-grid-angular';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
  RowClickedEvent,
  RowDoubleClickedEvent,
  SortChangedEvent
} from 'ag-grid-community';
import {catchError, map, Observable, of, tap} from 'rxjs';

import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZES, Maybe} from '@core/common';
import {CustomAgGridLoaderComponent} from '@shared/components/custom-ag-grid-loader';
import {AgGridSort, AgGridSortState, AgGridState} from '@shared/models';
import {NotificationService} from '@shared/services';

@Component({
  selector: 'aps-ag-grid-wrapper',
  standalone: true,
  imports: [CommonModule, AgGridModule, MatPaginator, MatProgressSpinner],
  templateUrl: './ag-grid-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgGridWrapperComponent<T = unknown> {
  private readonly notificationService = inject(NotificationService);

  @Input() pageSize = DEFAULT_PAGE_SIZE;

  @Input() pageSizeOptions = DEFAULT_PAGE_SIZES;

  @Input() columnDefs!: ColDef[];

  @Input() gridOptions!: GridOptions;

  @Input() state!: AgGridState<T>;

  @Output() paginationChanged = new EventEmitter<PageEvent>();

  @Output() sortChanged = new EventEmitter<Maybe<AgGridSortState[]>>();

  @Output() rowDoubleClicked = new EventEmitter<RowDoubleClickedEvent>();

  @Output() rowSingleClicked = new EventEmitter<RowClickedEvent>();

  total!: number;

  rowData$: Observable<Maybe<T[]>> = of(null);

  loadingOverlayComponent = CustomAgGridLoaderComponent;

  noRowsTemplate = '<span>No data available</span>';

  onResize(event: GridSizeChangedEvent<T>): void {
    event.api.sizeColumnsToFit();
  }

  onSortChanged<V>(event: SortChangedEvent<V>): void {
    const sortedColumns = event.api.getColumns()?.filter(column => column.getSort() !== undefined);
    if (sortedColumns?.length) {
      const events = [...sortedColumns]
        .sort((a, b) => a.getSortIndex()! - b.getSortIndex()!)
        .map(column => {
          const propertyName = column.getColDef().field;
          const order = column.getSort() === 'asc' ? AgGridSort.ASC : AgGridSort.DESC;
          return { propertyName, order };
        });
      this.sortChanged.emit(events as AgGridSortState[]);
    } else {
      this.sortChanged.emit(null);
    }
  }

  onGridReady(params: GridReadyEvent<T>): void {
    params.api.sizeColumnsToFit();
    this.rowData$ = this.state.data$
      .pipe(
        tap((res) => {
          this.total = res.pagination?.totalCount!;
        }),
        map((res) => res.data),
        catchError((e) => {
          if (e instanceof HttpErrorResponse) {
            this.notificationService.error(`Something went wrong. Details: ${e.message},${e.status}. Please contact support.`);
          }
          return of([]);
        })
      );
  }
}
