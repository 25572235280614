import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {UserBase} from '@features/users/models';
import {StatusComponent} from '@shared/components';
import {FullNamePipe, ValueOrDefaultPipe} from '@shared/pipes';

@Component({
  selector: 'aps-ag-cell-username',
  template: `
    <div class="flex items-center">
      {{ value  | apsFullName | apsValueOrDefault }}
      <aps-status *ngIf="showPendingLabel" class="ml-1" [type]="'warn'">Password Pending</aps-status>
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ValueOrDefaultPipe,
    FullNamePipe,
    StatusComponent,
    NgIf
  ]
})
export class AgCellUsernameComponent implements ICellRendererAngularComp {
  value!: UserBase;
  showPendingLabel = false;

  agInit(params: ICellRendererParams): void {
    this.initViewVariables(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.initViewVariables(params);
    return false;
  }

  private initViewVariables(params: ICellRendererParams): void {
    this.showPendingLabel = Boolean(params.data?.isNeedChangePasswordOnNextLogin);
    this.value = params.data;
  }

}
